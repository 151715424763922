<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="utf-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <title>404</title>
</head>

<body>

<div id="notfound">
    <div class="notfound">
        <div class="notfound-404">
            <h1>404</h1>
        </div>
        <h2>WOW~~页面找不到了</h2>
        <p>您访问的页面不存在，请返回首页~</p>
        <a href="/">回到首页</a>
    </div>
</div>

</body>

</html>
