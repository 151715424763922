import {Component} from '@angular/core';
import {AppService} from './core/services';

@Component({
    selector: 'app-root',
    template: `
        <router-outlet></router-outlet>
    `
})
export class AppComponent {
    constructor(
        private _app: AppService
    ) {
    }
}
