import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DefaultComponent} from './default/default.component';
import {HeaderComponent} from './header/header.component';
import {FooterComponent} from './footer/footer.component';
import {RouterModule} from '@angular/router';
import {InnerComponent} from './inner/inner.component';


@NgModule({
    declarations: [
        DefaultComponent,
        InnerComponent,
        HeaderComponent,
        FooterComponent
    ],
    imports: [
        CommonModule,
        RouterModule
    ],
    exports: [
        DefaultComponent,
        InnerComponent,
        HeaderComponent,
        FooterComponent
    ]
})
export class LayoutModule {
}
