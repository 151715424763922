import {AfterViewInit, Component, OnInit, ViewEncapsulation} from '@angular/core';

declare let $: any;

@Component({
    templateUrl: './default.component.html',
    styleUrls: ['./default.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DefaultComponent implements OnInit, AfterViewInit {

    constructor() {
    }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        $(window).scroll(function() {
            const window_top = $(window).scrollTop() + 1;
            if (window_top > 50) {
                $('.main_menu').addClass('menu_fixed animated fadeInDown');
            } else {
                $('.main_menu').removeClass('menu_fixed animated fadeInDown');
            }
        });
        setTimeout(() => {
            window.scroll(0,0);
        }, 100);
    }

}
