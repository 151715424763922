import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {E404Component} from './views/pages/error/e404/e404.component';
import {DefaultComponent} from './views/layout/default/default.component';
import {InnerComponent} from './views/layout/inner/inner.component';


const routes: Routes = [
    {
        path: '',
        component: DefaultComponent,
        loadChildren: () => import('./views/pages/home/home.module').then(m => m.HomeModule),
    },
    {
        path: 'article/:id',
        component: InnerComponent,
        loadChildren: () => import('./views/pages/article/article.module').then(m => m.ArticleModule),
    },
    {
        path: 'news',
        component: InnerComponent,
        loadChildren: () => import('./views/pages/news/news.module').then(m => m.NewsModule),
    },
    {
        path: 'brand',
        component: InnerComponent,
        loadChildren: () => import('./views/pages/brand/brand.module').then(m => m.BrandModule),
    },
    {
        path: 'benefit',
        component: InnerComponent,
        loadChildren: () => import('./views/pages/benefit/benefit.module').then(m => m.BenefitModule),
    },
    {
        path: 'pos',
        component: InnerComponent,
        loadChildren: () => import('./views/pages/pos/pos.module').then(m => m.PosModule),
    },
    {
        path: 'about.html',
        component: InnerComponent,
        loadChildren: () => import('./views/pages/about/about.module').then(m => m.AboutModule),
    },
    {
        path: '**',
        redirectTo: 'error/404.html',
        pathMatch: 'full'
    },
    {
        path: 'error/:type',
        component: E404Component
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        initialNavigation: 'enabled'
    })],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
