<div class="container">
    <div class="row align-items-center">
        <div class="col-lg-12">
            <nav class="navbar navbar-expand-lg navbar-light">
                <a class="navbar-brand d-flex align-items-center" routerLink="/" title="首页 - 微素生活">
                    <img src="assets/img/logo2.png" alt="LOGO 微素生活" style="height: 44px;width: 184px;">
                </a>
                <button class="navbar-toggler" type="button" data-toggle="collapse"
                        data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>

                <div class="collapse navbar-collapse main-menu-item justify-content-end" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <a class="nav-link" routerLink="/" title="首页 - 微素生活">首页</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" [routerLink]="['/news/index.html']" title="最新动态">动态</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" [routerLink]="['/brand/index.html']" title="品牌文化">品牌</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" [routerLink]="['/benefit/index.html']" title="微公益">微公益</a>
                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
                               data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" title="云餐饮">
                                云餐饮
                            </a>
                            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <a class="dropdown-item" [routerLink]="['/pos/about.html']" title="平台介绍">平台介绍</a>
                                <a class="dropdown-item" [routerLink]="['/pos/apply.html']" title="申请入驻">申请入驻</a>
                                <a class="dropdown-item" [routerLink]="['/pos/support.html']" title="技术支持">技术支持</a>
                            </div>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" [routerLink]="['/about.html']" title="关于我们">我们</a>
                        </li>
                    </ul>
                </div>
                <div class="menu_btn">
                    <a href="#" class="btn_1 d-none d-sm-block" title="加入我们">加入我们</a>
                </div>
            </nav>
        </div>
    </div>
</div>
