import {Component, OnInit} from '@angular/core';


@Component({
    selector: 'app-e404',
    templateUrl: './e404.component.html',
    styleUrls: ['./e404.component.scss']
})
export class E404Component implements OnInit {

    constructor(
    ) {
    }

    ngOnInit(): void {
    }

}
