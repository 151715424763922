// Angular
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {KeysPipe} from './pipe/keys.pipe';
import {CurrPipe} from './pipe/curr.pipe';
import {DefaultPipe} from './pipe/default.pipe';
import {AppService, HttpService} from './services';
import {BrPipe} from './pipe/br.pipe';

@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [
        KeysPipe,
        CurrPipe,
        DefaultPipe,
        BrPipe
    ],
    exports: [
        KeysPipe,
        CurrPipe,
        DefaultPipe,
        BrPipe
    ],
    providers: [
        AppService,
        HttpService
    ]
})
export class CoreModule {
}
