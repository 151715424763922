import {Injectable} from "@angular/core";

import CryptoJS from "crypto-js";
import {environment} from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SecurityLocalStorageService {

    key: string = environment.key;

    /**
     * 存储一个值或对象
     *
     * @param key
     * @param value
     */
    set(key, value) {
        if (!key) {
            return null;
        }

        let ciphertext = CryptoJS.AES.encrypt(JSON.stringify(value), this.key).toString();
        localStorage.setItem(key, ciphertext);

        return ciphertext;
    }

    /**
     * 获取一个值或对象
     *
     * @param key
     */
    get(key) {
        if (!key) {
            return null;
        }

        let ciphertext = localStorage.getItem(key);

        if (!ciphertext) {
            return ciphertext;
        }

        let bytes = CryptoJS.AES.decrypt(ciphertext, this.key);
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }

    /**
     * 删除一个值或对象
     *
     * @param key
     */
    del(key) {
        if (!key) {
            localStorage.removeItem(key);
        }
    }

    /**
     * 清空存储
     */
    cer() {
        localStorage.clear();
    }

    /**
     * 加密
     *
     * @param value
     */
    en(value: any) {
        return CryptoJS.AES.encrypt(JSON.stringify(value), this.key).toString();
    }

    /**
     * 解密
     *
     * @param ciphertext
     */
    de(ciphertext: string) {
        let bytes = CryptoJS.AES.decrypt(ciphertext, this.key);
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }
}
