import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable, throwError} from 'rxjs';
import {MyResponse} from './MyResponse.interface';
import {catchError} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class HttpService {

    constructor(
        private http: HttpClient,
    ) {
    }

    public url(url: string, api = true) {
        if (url && !url.startsWith('https://') && !url.startsWith('http://')) {
            url = (0 == url.indexOf('/')) ? url : '/' + url;
            url = environment.host + (api ? environment.api : '') + url;
        }
        return url;
    }

    public host() {
        return environment.host;
    }

    public error(error: HttpErrorResponse) {
        console.error(error);
        if (401 === error.status) {
            return throwError('用户登录失效');
        } else if (403 === error.status) {
            return throwError('没有操作权限');
        } else if (404 === error.status) {
            return throwError('服务器连接失败');
        } else if (0 === error.status) {
            return throwError('服务器连接失败');
        } else if (500 === error.status) {
            return throwError('服务器发生错误');
        } else {
            return throwError('客户端发生错误');
        }
    }

    public headers(params = {}) {
        return {
            ...params,
            'Accept': 'application/json',
        };
    }

    public httpOptions(form: boolean = false) {
        return {
            headers: new HttpHeaders({
                'Content-Type': form ? 'application/x-www-form-urlencoded' : 'application/json',
                'Accept': 'application/json',
            }),
        };
    }

    get(url): Observable<string | MyResponse | any[]> {
        return this.http.get<string | MyResponse | any[]>(this.url(url), this.httpOptions()).pipe(
            catchError(this.error)
        );
    }

    post(url, data, form: boolean = false): Observable<MyResponse> {
        return this.http.post<MyResponse>(this.url(url), data, this.httpOptions(form)).pipe(
            catchError(this.error)
        );
    }

    put(url, data, form: boolean = false): Observable<MyResponse> {
        return this.http.put<MyResponse>(this.url(url), data, this.httpOptions(form)).pipe(
            catchError(this.error)
        );
    }

    delete(url): Observable<MyResponse> {
        return this.http.delete<MyResponse>(this.url(url), this.httpOptions()).pipe(
            catchError(this.error)
        );
    }
}
