<div class="container">
    <div class="row">
        <div class="col-xl-3 col-sm-6 col-md-3 col-lg-3">
            <div class="single-footer-widget footer_1">
                <h4>关于我们</h4>
                <p>概而言之，微素生活是我们一群小伙伴共同发起的一个"素"事业。</p>
            </div>
        </div>
        <div class="col-xl-3 col-sm-6 col-md-2 col-lg-3">
            <div class="single-footer-widget footer_2">
                <h4>相关链接</h4>
                <div class="contact_info">
                    <ul>
                        <li><a href="https://www.wesoon.net">微素生活</a></li>
                        <li><a href="https://merchant.wesoon.net">微素合作商户服务平台</a></li>
                        <li><a href="https://www.wesoon.net/pos/support">软件下载</a></li>
                        <li><a href="https://map.wesoon.net">素食地图</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-sm-6 col-md-3 col-lg-3">
            <div class="single-footer-widget footer_2">
                <h4>联系我们</h4>
                <div class="contact_info">
                    <p><span> 通信地址 :</span>成都市 / 武侯区 / 领事馆路7号附6号</p>
                    <p><span> 联系电话 :</span>+86 156 8005 6616</p>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-sm-6 col-md-4 col-lg-3">
            <div class="single-footer-widget footer_3">
                <h4>推荐关注</h4>
                <p><img src="assets/img/wechat_search.png"/></p>
            </div>
        </div>
    </div>
    <div class="copyright_part_text">
        <div class="row">
            <div class="col-lg-8">
                <p class="footer-text m-0">微素生活 &copy; 2020 保留所有权利 | <a href="https://www.wesoon.net" target="_blank">四川微素生活科技有限公司</a> | <a href="https://beian.miit.gov.cn" target="_blank">蜀ICP备18014765号-1</a>

            </div>
            <div class="col-lg-4">
                <div class="copyright_social_icon text-right">
                    <a href="#"><i class="fab fa-weibo"></i></a>
                    <a href="#"><i class="fab fa-weixin"></i></a>
                    <a href="#"><i class="fab fa-zhihu"></i></a>
                    <a href="#"><i class="fab fa-qq"></i></a>
                    <a href="#"><i class="fab fa-twitter"></i></a>
                    <a href="#"><i class="fab fa-youtube"></i></a>
                </div>
            </div>
        </div>
    </div>
</div>
